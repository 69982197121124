import React from 'react';

import {useState} from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import ContentHeadline from './ingredients/ContentHeadline';
import Fade from 'react-reveal/Fade';

function TemplateFext() {
    const [validated,
        setValidated] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false); // Zustand für Formular-Submi


    const postDataToAPI = async(data) => {
        try {
            if (!data.rueckruf) {
                data.rueckruf = "false"; // Wenn nicht ausgewählt, setzen Sie auf "false"
            } else {
                data.rueckruf = "true"; // Wenn ausgewählt, setzen Sie auf "true"
            }

            console.log(data);
            console.log(JSON.stringify(data));

            const response = await fetch('https://api.home-build-service.de/sendMail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('Fehler bei der API-Anfrage:', error);
            return null;
        }
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);

            // Access the form data here
            const formData = new FormData(form);

            // Erstellen Sie ein leeres JavaScript-Objekt, um die Formulardaten zu speichern
            const formDataObject = {};

            // Iterieren Sie über das formData-Objekt und füllen Sie das JavaScript-Objekt
            // damit
            for (let [name,
                value]of formData.entries()) {
                formDataObject[name] = value;
            }

            // Senden Sie die Daten an die API
            const responseData = await postDataToAPI(formDataObject);

            if (responseData) {
                // Hier können Sie die Antwort der API verarbeiten, wenn es erfolgreich war
                console.log('API-Antwort:', responseData);
                setFormSubmitted(true); // Setze den Zustand auf true nach erfolgreichem Absenden
            }

            // Do further processing if needed
        }
    };

    return (
        <div id="2" className="template-form px-3 py-5">
            <div className="container">

                {formSubmitted
                    ? ( // Anzeige abhängig vom Formular-Submit-Zustand < Fade > {/* Hier kannst du die Komponente anzeigen, die nach dem Absenden erscheinen soll */
                    <div>
                        <Alert className='submit-success font-poppins-medium p-2'>
                            Vielen Dank! Ihre Anfrage wurde erfolgreich übermittelt.
                            Wir werden uns in Kürze mit Ihnen in Verbindung setzen.
                        </Alert>
                    </div>
                ) : (
                    <div>
                    <ContentHeadline headline={"Kontaktformular"}></ContentHeadline > <div className="row mt-3">
                        <Fade left>
                            <Form
                                className="font-poppins"
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}>
                                <Row>
                                    <Form.Group as={Col} controlId="formGridAnliegen">
                                        <FloatingLabel label="Anliegen">
                                            <Form.Select
                                                name="anliegen"
                                                className="rounded-0"
                                                aria-label="Floating label select example">
                                                <option value="Beratung & Ausstattung">Beratung & Planung</option>
                                                <option value="Renovierung">Renovierung</option>
                                                <option value="Bodenverlegung">Bodenverlegung</option>
                                                <option value="Lifestyle">Lifestyle</option>
                                                <option value="Decken">Decken</option>
                                                <option value="Fassaden">Fassaden</option>
                                                <option value="Montagen">Montagen</option>
                                                <option value="Raumausstattung">Raumausstattung</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Row>
                                <Row className="mt-4">
                                    <Form.Group as={Col} controlId="formGridVorname">
                                        <FloatingLabel label="Vorname">
                                            <Form.Control
                                                required
                                                type="text"
                                                name="vorname"
                                                placeholder="-"
                                                className="rounded-0"/>
                                        </FloatingLabel>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridNachname">
                                        <FloatingLabel label="Nachname">
                                            <Form.Control
                                                required
                                                type="text"
                                                name="nachname"
                                                placeholder="-"
                                                className="rounded-0"/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Row>
                                <Row className="mt-4">
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <FloatingLabel label="E-Mail">
                                            <Form.Control
                                                required
                                                type="email"
                                                name="email"
                                                placeholder="-"
                                                className="rounded-0"/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Row>
                                <Row className="mt-4">
                                    <Form.Group as={Col} controlId="formGridTelefon">
                                        <FloatingLabel label="Telefon">
                                            <Form.Control
                                                required
                                                type="text"
                                                name="telefon"
                                                placeholder="-"
                                                className="rounded-0"/>
                                        </FloatingLabel>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            name="rueckruf"
                                            label="Bitte um Rückruf"
                                            className="mt-3"
                                            defaultValue={false}/>
                                    </Form.Group>
                                </Row>
                                <Row className="mt-5">
                                    <Form.Group as={Col} controlId="formGridNachricht">
                                        <FloatingLabel label="Ihre Nachricht">
                                            <Form.Control
                                                required
                                                as="textarea"
                                                name="nachricht"
                                                placeholder="Leave a comment here"
                                                style={{
                                                height: '100px'
                                            }}
                                                className="rounded-0"/>
                                        </FloatingLabel>
                                    </Form.Group>
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mt-3">
                                            <Form.Check
                                                required
                                                inline
                                                label="Die Datenschutzerklärung habe ich gelesen und akzeptiert."
                                                name="datenschutz"
                                                type={type}
                                                id={`inline-${type}-1`}/>
                                        </div>
                                    ))}
                                </Row>
                                <Button className="mt-5 rounded-0" type="submit">Anfrage abschicken</Button>
                            </Form>
                        </Fade>
                    </div> 
                </div>

                )}
            </div>
        </div>
    )
}

export default TemplateFext;