// Datenschutz.js

import React, {useState} from 'react';

import TemplateText from '../TemplateText';
import TemplateForm from '../TemplateForm';
import TemplateFooter from '../TemplateFooter';
import Fade from 'react-reveal/Fade';

import MenuOverlay from '../ingredients/MenuOverlay';
import TemplateOpener from '../TemplateOpener';
import TemplatePortfolio from '../TemplatePortfolio';


const StaticHome = () => {
    const [showMenu,
        setShowMenu] = useState(false);
    const [isActive,
        setIsActive] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
        setIsActive(!isActive);
    };
  
    return (
    <div>
       <TemplateOpener toggleMenu={toggleMenu} isActive={isActive}/> {showMenu && <MenuOverlay toggleMenu={toggleMenu} isActive={isActive}/>}

      <Fade bottom duration={50}>
            <TemplateText
                headline="Wer wir sind."
                text="Du liebst dein Zuhause, und wir lieben es, dir zu helfen, es zu gestalten. Willkommen bei Home-Build-Service, deinem vertrauenswürdigen Partner für maßgeschneiderte Heimwerklösungen! Als erfahrener Handwerksprofi stehen wir für Qualität, Kreativität und eine Rundum-Betreuung, um deine Wohnträume Wirklichkeit werden zu lassen."
                theme={'orange'}
                identifier={'1'}/>
        </Fade>
            
      

        <Fade bottom duration={50}>
            <TemplateForm/>
        </Fade>

        <Fade bottom duration={50}>
            <TemplateText
                headline="Wir liefern erstklassige Ergebnisse, die Ihren Anforderungen gerecht werden."
                text="Möchten Sie Ihr Zuhause in neuem Glanz erstrahlen lassen? Unsere Handwerker sind Ihre zuverlässigen Partner für professionelle Ergebnisse. Erleben Sie perfekte Farbharmonie und präzise Ausführung - für ein Zuhause, das Sie stolz präsentieren können. Möchtest du dein Zuhause in einen Ort voller Stil und Komfort verwandeln? Kontaktiere uns noch heute für eine kostenlose Beratung. Home-Build-Service, wo Handwerkskunst auf Leidenschaft trifft, um deine Wohnträume Wirklichkeit werden zu lassen."
                identifier={'3'}/>
        </Fade>


        <Fade>
        <TemplatePortfolio/>
        </Fade>
        <TemplateFooter/>
    </div>
  );
};

export default StaticHome;
