import React, {useState} from "react";
import Slide from "react-reveal/Slide";
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';

const MenuOverlay = ({isActive, toggleMenu}) => {

    const handleScrollClick = (targetId) => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }

        toggleMenu();
    };

    return (
        <div>
            <Slide left duration={600}>
                <div className={`menu-overlay vh-100 vw-100 bgc-black`}>

                    <Fade left delay={50}>
                        <div className="menu-item container px-2 py-5">
                            <button className={`burger menu ${isActive
                                ? "active"
                                : ""}`} onClick={toggleMenu} // Call the toggleMenu function to close the menu
                            ></button>
                        </div>
                    </Fade>

                    <Fade left delay={800}>
                        <div className="menu-item container px-2">
                            <div className="d-flex justify-content-start">
                                <div className="menu-item-content" onClick={() => handleScrollClick('0')}>
                                    <h2 className="display-1 font-teko text-c-primary mb-0 float-left">Home</h2>
                                    <p className="mt-2 text-c-primary font-poppins float-left">01</p>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade right delay={1200}>
                        <div className="menu-item container px-2">
                            <div className="d-flex justify-content-end">
                                <a href="https://portfolio.home-build-service.de/#/" className="menu-item-content">
                                    <p className="mt-2 text-c-primary font-poppin float-left">02</p>
                                    <h2 className="display-1 font-teko text-c-primary mb-0 float-left">Portfolio</h2>
                                </a>
                            </div>
                        </div>
                    </Fade>


                    <Fade left delay={1600}>
                        <div className="menu-item container px-2">
                            <div className="d-flex justify-content-start">
                                <div className="menu-item-content" onClick={() => handleScrollClick('2')}>
                                    <h2 className="display-1 font-teko text-c-primary mb-0">Kontakt</h2>
                                    <p className="mt-2 text-c-primary font-poppins">03</p>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade right delay={1800}>
                        <div className="menu-item container px-2">
                            <div className="d-flex justify-content-end">
                                <div className="menu-item-content">
                                    <Link to="/impressum" className="link-styling">
                                        <p className="mt-2 text-c-primary font-poppins">04</p>
                                        <h2 className="display-1 font-teko text-c-primary mb-0">Impressum</h2>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade left delay={2000}>
                        <div className="menu-item container px-2">
                            <div className="d-flex justify-content-start">
                                <div className="menu-item-content">
                                    {/* Verwende Link um den Text und das Styling beizubehalten */}
                                    <Link to="/datenschutz" className="link-styling">
                                        <h2 className="display-1 font-teko text-c-primary mb-0">Datenschutz</h2>
                                        <p className="mt-2 text-c-primary font-poppins">05</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Fade>

                </div>
            </Slide>
        </div>
    );
}
export default MenuOverlay