// App.js
import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.scss';

import { useEffect } from 'react';

import Datenschutz from './components/statics/StaticDatenschutz';
import Impressum from './components/statics/StaticImpressum';
import Home from './components/statics/StaticHome';

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home />}/>
                    <Route path="/datenschutz" element={<Datenschutz />}/>
                    <Route path="/impressum" element={<Impressum />}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
