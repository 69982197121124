import React from "react";
import Logo from "../logo/1_Transparent_Image_small.png";

const TemplateHeader = ({toggleMenu, isActive}) => {
    const handleScrollClick = () => {
        const targetElement = document.getElementById('2');
        if (targetElement) {
            targetElement.scrollIntoView({behavior: 'smooth'});
        }
    };

    return (
        <div className={`template-header`}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <button
                            className={`burger ${isActive
                            ? "active"
                            : ""}`}
                            onClick={toggleMenu}></button>
                    </div>
                    <div className="col text-center">
                        <img src={Logo} className="logo image-fluid center-block"></img>
                    </div>
                    <div className="col">
                        <button
                            type="button"
                            className="btn btn-primary rounded-0 font-poppins float-end"
                            onClick={handleScrollClick}>Kontakt</button>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default TemplateHeader;
