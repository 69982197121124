import React, {useRef, useEffect, useState} from 'react';
import HeroImage from "../images/hero79kB.jpg"

import TemplateHeader from './TemplateHeader';
import Typed from 'react-typed';

import Fade from "react-reveal";
import Slide from 'react-reveal/Slide';

const TemplateOpener = ({toggleMenu, isActive}) => {
    const [imageHeight,
        setImageHeight] = useState(0);
    const imageRef = useRef(null);
    const targetDivRef1 = useRef(null);
    const targetDivRef2 = useRef(null);

    // Funktion, um die Höhe des Bild-Tags zu aktualisieren
    const updateImageHeight = () => {
        if (imageRef.current) {
            setImageHeight(imageRef.current.clientHeight);
        }
    };

    // Event-Handler für das Ändern der Fenstergröße
    const handleResize = () => {
        updateImageHeight();
    };

    // useEffect für den Start und das Abhängigkeitsarray []
    useEffect(() => {
        // Bildhöhe aktualisieren, sobald das Bild vollständig geladen ist
        if (imageRef.current && imageRef.current.complete) {
            updateImageHeight();
        } else {
            imageRef.current.onload = updateImageHeight;
        }

        // Event-Listener für Größenänderungen des Fensters hinzufügen
        window.addEventListener('resize', handleResize);

        // Aufräumen, wenn die Komponente unmontiert wird
        return () => {
            window.removeEventListener('resize', handleResize);
            if (imageRef.current) {
                imageRef.current.onload = null;
            }
        };
    }, []);

    // useEffect für das Update der Ziel-Div-Höhen bei Änderungen der Bildhöhe
    useEffect(() => {
        if (targetDivRef1.current && targetDivRef2.current) {
            targetDivRef1.current.style.height = `${imageHeight}px`;
            targetDivRef2.current.style.height = `${imageHeight}px`;
        }
    }, [imageHeight]);

    return (

        <div id="0" className={`template-opener d-flex flex-column px-3 py-5`}>
            <Fade left duration={50}>
                <TemplateHeader toggleMenu={toggleMenu} isActive={isActive}  />

                <div className="container second mt-5">
                    <div ref={targetDivRef1} className="w-100 m-0 position-relative height-setter">

                        <div className="h-100 d-flex content-holder">

                            <h1 className="font-teko mt-4 m-md-0">
                                <Typed strings={['Handwerk aus einer Hand mit Leidenschaft.']} typeSpeed={40}/></h1>

                            <div className="seperator mt-2 mb-3"></div>

                            <div className="bottom-box desktop p-3 mt-1">
                                <p className="font-poppins-medium m-0">Die handwerkliche Tätigkeit, die von der
                                    industriellen Massenproduktion abzugrenzen ist.</p>
                            </div>
                        </div>

                        <div ref={targetDivRef2} className="overlapper position-absolute">

                            <img
                                src={HeroImage}
                                ref={imageRef}
                                className="img-fluid position-relative img-hero"></img>
                        </div>
                    </div>
                </div>

                <div className='container'>
                    <div className="bottom-box mobile p-2 mt-3">
                        <p className="font-poppins-medium m-0">Die handwerkliche Tätigkeit, die von der
                            industriellen Massenproduktion abzugrenzen ist.</p>
                    </div>
                </div>

            </Fade>
        </div>
    );
}
export default TemplateOpener