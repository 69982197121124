import React, { useEffect, useState } from 'react';
import ContentHeadline from './ingredients/ContentHeadline';
import Bounce from 'react-reveal/Bounce';
import Button from 'react-bootstrap/Button';

const TemplatePortfolio = ({}) => {
    return (
        <div className="template-portfolio bgc-primary px-3 py-5">
            <div className='container'>
            <ContentHeadline headline={'Unser Portfolio'} theme={'orange'}></ContentHeadline>
                <Bounce left>
                    <p className="text-left m-0 mt-3 font-poppins">{'Entdecken Sie die handwerkliche Meisterschaft und kreative Vision unseres Unternehmens im Portfolio. Tauchen Sie ein und lassen Sie sich von unserer Expertise inspirieren.'}</p>
                </Bounce>
                <Bounce left>
                <div id='portfolio' className='h-100 d-flex align-items-center justify-content-center'>
                    <iframe src="https://portfolio.home-build-service.de/#/" width="100%" height="600px" frameborder="0" scrolling="auto"></iframe>
                </div>
                </Bounce>
            </div>
        </div>
    );
}
export default TemplatePortfolio