// Datenschutz.js
import React from 'react';
import ContentHeadline from '../ingredients/ContentHeadline';

const StaticImpressum = () => {
  return (
    <div>
      <div className={`px-3 py-5 bgc-light vh-100`}>
            <div className="container">
                <ContentHeadline headline={'Impressum'}></ContentHeadline>
                    <div className="text-left m-0 mt-3 font-poppins">
                    
                        <h2>Anschreiben:</h2>
                        <p>Marschowitzer Str. 18<br/>
                        87600 Kaufbeuren<br/>
                        Vertreten durch: Michael Müller<br/>
                        E-Mail: kontakt@home-build-service.de</p>

                        <p><strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong><br />
                        Michael Müller<br/>
                        Marschowitzer Str. 18<br/>
                        87600 Kaufbeuren</p>

                        <p><strong>Haftungsausschluss:</strong><br />
                        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.</p>

                    
                    </div>
            </div>
        </div>
    </div>
  );
};

export default StaticImpressum;
